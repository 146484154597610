<h1 style="margin-top: 30px">Home</h1>
<mat-progress-spinner></mat-progress-spinner>
<div class="d-flex justify-content-around align-items-center flex-wrap">
    <button mat-flat-button class='mr-3 ml-3 menu-block mb-5' [routerLink]="['/customer/create']" routerLinkActive="router-link-active">
        <div class="d-flex justify-content-center align-items-center">
            <span>New customer</span>
        </div>
    </button>
    <button mat-flat-button class='mr-3 ml-3 menu-block mb-5' [routerLink]="['/customer/list']" routerLinkActive="router-link-active">
        <div class="d-flex justify-content-center align-items-center">
            <span>Customers to sync</span>
        </div>
    </button>
</div>