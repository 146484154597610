import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NetworkService } from 'app/services/network.service';
import { Router } from '@angular/router';
import { User, UserRoles } from 'app/models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  routes: Array<{data: {title: string}, path: string}>;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public _networkService: NetworkService,
    private router: Router
  ) {
   this.userService._user.subscribe((user) => this.initRoutes(user));
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    
  }

  initRoutes(user: User) {
    this.routes = [];
    // CAN ACCESS ADMINISTRATION
    if(user?.roles.includes(UserRoles.SPADMIN)) {
      this.routes.push({data: {title: "administration.title"}, path: "administration/optins"});
    }

    // CAN ACCESS USERS
    if(user?.roles.includes(UserRoles.SPADMIN) || user?.roles.includes(UserRoles.ADMIN)) {
      this.routes.push({data: {title: "administration.title-btn"}, path: "administration/users"});
    }
    this.routes.push({data : {title: "history.title-btn"}, path: "history"});

  }

  logout() {
    this.router.navigate(['/auth/logout']);
  }

}
