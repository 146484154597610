import { Routes } from '@angular/router';
import { LoggedInGuard } from '@ff/core';
import { CanAccessFormGuard } from 'app/_guards/can-access-form.guard';
import { HomeComponent } from './components/home/home.component';

export const routes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard, CanAccessFormGuard], data: { title: 'Home' } },
    {
        path: 'customer',
        loadChildren: () => import('../customer/customer.module').then(m => m.CustomerModule), canActivate: [LoggedInGuard]
    },
    {
        path: 'administration',
        loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule), canActivate: [LoggedInGuard]
    },
    {
        path: 'history',
        loadChildren: () => import('../history/history.module').then(m => m.HistoryModule), canActivate: [LoggedInGuard]
    },
    { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
