import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/models/user';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService as FFService } from '@ff/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  resource: string;

  constructor(
    private http: HttpClient,
    private _user: FFService
  ) {
    this.resource = environment.api.host + '/users';
  }

  getUsers() {
    return this.http.get<{ data: User }>(this.resource).pipe(map((res: any) => res.data));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.resource + '/' + id);
  }

  save(formValue: User) {
    if (formValue && formValue.id) {
      return this.edit(formValue);
    }
    return this.http.post<{ data: User }>(this.resource, formValue);
  }

  edit(formValue: User) {
    return this.http.put<{ data: any }>(this.resource + '/' + formValue.id, formValue);
  }

  getUser() {
    if (!!window.navigator.onLine) {
      return this._user.getUser().value;
    } else {
      return JSON.parse(localStorage.getItem('user'));
    }
  }
}
