import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { User, UserRoles } from 'app/models/user';
import { UserService } from 'app/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanAccessFormGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let user = new User(this.userService.getUser());
      if (user.roles.includes(UserRoles.SPADMIN) && !user.roles.includes(UserRoles.ADMIN) && !user.roles.includes(UserRoles.STATION_STAFF)) {
        this.router.navigate(['/administration']);
      }
      return true;
  }
  
}
