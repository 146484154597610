import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'app/models/user';
import { CguService } from 'app/services/cgu.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

  text?: string;
  cguChecked = false;
  user: User;
  link: string;

  constructor(
    public dialogRef: MatDialogRef<CguComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public _cguService: CguService,
    private _userService: UserService,
  ) { 
    this.user = this._userService.getUser();
    this.text = data;
    this.cguChecked = this._cguService.hasReadCGU();
    this.link = this._cguService.getLink(this.user.country);
  }

  checkCGU(checked) {
    this.cguChecked = checked;
  }

  ngOnInit(): void {
  }

}
