<div class="d-flex flex-row">
    <div class="check-icon icon-override size-32" style="margin-right: 16px; margin-top: 2px;"></div>
    <div class="d-flex flex-column">
        <div class="snack-title" *ngIf="data && data.title">{{data?.title | translate}}</div>
        <div class="d-flex flex-row success-row" *ngIf="data.success && data.success > 0">
            <div class="check-icon icon-override size-20" style="margin-right: 16px; margin-top: 2px;"></div>
            <div class="snack-text">
                {{data.success}}{{ 'sync_done.success' | translate }}
            </div>
        </div>
        <div class="d-flex flex-row success-row" *ngIf="data.failed && data.failed > 0">
            <div class="offline-icon icon-override size-20" style="margin-right: 16px; margin-top: 2px;"></div>
            <div class="snack-text">
                {{data.failed}}{{ 'sync_done.failed' | translate }}
            </div>
        </div>
    </div>
</div>