import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule } from '@ff/at-internet';
import { AUTH_CONFIG, AuthModule } from '@ff/auth';
import { USER_CONFIG, UserModule } from '@ff/user';
import { environment } from '../../../environments/environment';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { routes } from './core.routes';
import { ServiceWorkerModule } from '@angular/service-worker';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { ServiceLocator } from 'app/services/_locator.service';
import { OAUTH2_CONFIG } from '@ff/oauth2';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
        HomeComponent,
    ],
    providers: [
        {
            provide: API_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 }},
        {provide: USER_CONFIG, useValue: {host: environment.api.host}},
        {provide: AUTH_CONFIG, useValue: {host: environment.api.host}},
        {
            provide: OAUTH2_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    bearer: '/oauth2/token',
                    providers: '/oauth2/providers'
                }
            }
        },
    ],
    imports: [

        // FF packages

        UserModule,
        AuthModule,
        ATInternetModule,

        // Angular Packages
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        // App packages
        SharedModule,
        AppAuthModule,
        AppUserModule,
        ServiceWorkerModule.register('ngsw-worker.js', { 
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        })
    ],
    bootstrap: [
        AppComponent
    ]
})

export class CoreModule {

    public constructor(
        private adapter: DateAdapter<Date>,
        private translate: TranslateService,
        @Optional() @SkipSelf() parent: CoreModule,
        private injector: Injector
    ) {
        if (parent) {
            throw new Error('CoreModule should not be exported then imported by another module!');
        }
        this.translate.onLangChange
            .subscribe(({ lang }: LangChangeEvent) => {
                this.adapter.setLocale(lang);
        });
        this.translate.addLangs(['fr','en']);
        ServiceLocator.injector = injector;
    }

    

}
