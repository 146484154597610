<h4 mat-dialog-title class="text-uppercase">Reset your password</h4>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <p>You can reset your password by providing your email address</p>
    <mat-form-field class="w-100">
        <input matInput prefixIcon="envelope-o" placeholder="email@domain.com" type="email" formControlName="email" />
    </mat-form-field>
</form>
<div mat-dialog-actions class="d-flex justify-content-start">
    <button mat-raised-button class="align-self-start py-1" color="primary" [disabled]="!form.valid" (click)="submit()">
        <mat-icon>arrow_right</mat-icon>
        Send
    </button>
</div>
