import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { ProgressSnackbarComponent } from 'app/modules/shared/components/progress-snackbar/progress-snackbar.component';
import { SuccessSnackbarComponent } from 'app/modules/shared/components/success-snackbar/success-snackbar.component';
import { SyncDoneSnackbarComponent } from 'app/modules/shared/components/sync-done-snackbar/sync-done-snackbar.component';
import { WarningSnackbarComponent } from 'app/modules/shared/components/warning-snackbar/warning-snackbar.component';
import {Observable, Subject} from 'rxjs';
import { CustomerService } from './customer.service';
import {SalesforceService} from './salesforce.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    networkStatusChanged: Subject<boolean> = new Subject<boolean>();

    constructor(
        private snackbar: MatSnackBar,
        private _customerService: CustomerService,
        private _userService: UserService,
        private _loader: LoaderService,
        private salesforceService: SalesforceService
    ) { 
        this.updateNetworkStatus(window.navigator.onLine ? 'online' : 'offline');
    }
    
    initEvents(): void {
        window.addEventListener('online', () => this.updateNetworkStatus('online'));
		window.addEventListener('offline', () => this.updateNetworkStatus('offline'));
    }
    
    get connectionChanged(): Observable<boolean> {
		return this.networkStatusChanged.asObservable();
	}
    
    get isOnline() {
		return !!window.navigator.onLine;
	}
    
    private updateNetworkStatus(status: string): void {
		if (status === 'online') {
            if (this._customerService.getFromLocalStorage()?.length > 0) {
                this.sync();
            } else {
                this.showOnlineSnack();
            }
		} else {
			this.showOfflineSnack();
		}
		this.networkStatusChanged.next(window.navigator.onLine);
	}

    updateCustomersStatus(status): void {        
        this._customerService.getFromLocalStorage().forEach(customer => customer.status = status);
    }

    updateCustomersStatusAfterSyncing(results): void {
        let updatedCustomerList = [];
        let nbSyncedCustomers = 0;
        this._customerService.getFromLocalStorage().forEach((customer, i) => {
            if (!this.validateOptinsResponse(results[i])) {
                customer.status = 'Sync failed';
                updatedCustomerList.push(customer);
            } else {
                customer.status = 'Synced';
                nbSyncedCustomers++;
            }
        });

        this._customerService.updateAccountInLocalStorage(updatedCustomerList);
        
        this.showSyncDone(nbSyncedCustomers, updatedCustomerList.length);
    }

    // since each optin is has a request we check if all requests are successful (we returned array of observables)
    validateOptinsResponse(optins: Array<any>) {
        let allSuccess = true;
        optins.forEach((optinResponse) => {
            allSuccess &&= (optinResponse.code != null && optinResponse.code == 200);
        });
        return allSuccess;
    }

    showOnlineSnack() {
        if (this._userService.getUser().value) {
            this.snackbar.openFromComponent(SuccessSnackbarComponent, {
            data: {
                title: 'network.online.title',
                text: 'network.online.text'
            },
            duration: 3000,
            panelClass: ['success-snack']

            });
        }
    }

    showProgressSnack() {
        if (this._userService.getUser().value) {
            this.snackbar.openFromComponent(ProgressSnackbarComponent, {
            data: {
                title: 'sync_progress.title',
                text: 'sync_progress.text'
            },
            panelClass: ['progress-snack'],
            duration: null
            });
        }
    }

    showSyncDone(success, failed) {
        if (this._userService.getUser().value) {
            this.snackbar.openFromComponent(SyncDoneSnackbarComponent, {
                data: {
                    title: 'sync_done.title',
                    success: success,
                    failed: failed
                },
                
                panelClass: ['success-snack']
            });
        }

    }

    showOfflineSnack() {
        if (this._userService.getUser().value) {
            this.snackbar.openFromComponent(WarningSnackbarComponent, {
                data: {
                title: 'network.offline.title',
                text: 'network.offline.text'
                },
                duration: 3000,
                panelClass: ['warning-snack']
            });
        }
    }

    
    sync(): void {
        // confirm
        
        this.showProgressSnack();
        
        this.updateCustomersStatus('Syncing');
        this._customerService.sync(this._customerService.getFromLocalStorage()).subscribe(results => {
            this.snackbar.dismiss();
            this.updateCustomersStatusAfterSyncing(results);
        }, err => {
            this.snackbar.dismiss();
        });
    }
}
