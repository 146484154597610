
export interface ICanal {
    type: string;
    enabled: boolean;
    checked?: boolean;
}

export class Canal implements ICanal {
    type: string;
    enabled: boolean;
    checked?: boolean;

    constructor (fill: Canal) {
        this.type = fill.type;
        this.enabled = fill.enabled;
        this.checked = fill.checked;
        
    }

    toJson() {
        return {
            type: this.type,
            enabled: this.enabled,
        };
    }
}
