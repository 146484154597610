<nav class="align-items-end desktop">
    <ul>
        <ng-container *ngFor="let route of getRoutes()">
            <ng-container *ngIf="route.data?.title">
                <!-- No child -->
                <ng-container *ngIf="!route.children || (route.children && route.children.length == 0)">
                    <li class="mr-5" [routerLink]="route.path" routerLinkActive="active">{{route.data?.title | translate}}</li>
                </ng-container>
                <!-- Has children -->
                <ng-container *ngIf="route.children && route.children.length > 0">
                    <li class="mr-5" [matMenuTriggerFor]="menu" [routerLink]="route.path" routerLinkActive="active">{{route.data?.title | translate}}</li>
                    <mat-menu class="py-3 px-2" #menu="matMenu" [overlapTrigger]="false">
                        <ng-container *ngFor="let child of route.children">
                            <button mat-menu-item  *ngIf="child.data?.title" [routerLink]="route.path + '/' + child.path">{{child.data?.title | translate}}</button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </ng-container>
    </ul>
</nav>
<nav class="mobile">
    <ul *ngFor="let route of getRoutes()">
        <ng-container *ngIf="route.data?.title">
            <li (click)="close()" class="pointer" routerLinkActive="active" [routerLink]="route.path" *ngIf="route.path">
                {{route.data.title | translate}}
            </li>
            <!-- Has children -->
            <ng-container *ngIf="route.children && route.children.length > 0">
                <li (click)="close()" class="pointer" routerLinkActive="active" [routerLink]="route.path + '/' + child.path" *ngFor="let child of route.children">
                    {{child.data.title | translate}}
                </li>
            </ng-container>
        </ng-container>
    </ul>
</nav>
