import { Component, OnInit } from '@angular/core';
import { UserService } from '@ff/user';
import { UserRoles } from 'app/models/user';
import {NetworkService} from 'app/services/network.service';
import {SalesforceService} from 'app/services/salesforce.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(
        private salesforceService: SalesforceService,
        private _userService: UserService
    ) {
        
    }

    ngOnInit() {
    }

    isAdmin() {
      return this._userService.getUser()?.value?.role == UserRoles.ADMIN;
    }
}
