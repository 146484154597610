import { Canal } from "./canal";
import { Translatable } from "./translatable";

export interface IOptin {
    id?: number;
    key: string;
    country: string;
    label: Translatable;
    text: Translatable;
    canals: Canal[];
}

export class Optin implements IOptin{
    id?: number;
    key: string;
    country: string;
    label: Translatable;
    text: Translatable;
    canals: Canal[];

    constructor (fill: Optin) {
        this.id = fill.id;
        this.key = fill.key;
        this.country = fill.country;
        this.label = new Translatable(fill.label);
        this.text = new Translatable(fill.text);
        this.canals = [];
        if (fill.canals && fill.canals.length > 0) {
            fill.canals.forEach(c => this.canals.push(new Canal(c)));
        }
    }

    toJson() {
        let canals = [];
        this.canals.forEach(c => canals.push(c.toJson()));
        return {
          id: this.id,
          key: this.key,
          country: this.country,
          label: this.label.toJson(),
          text: this.text.toJson(),
          canals: canals
        };
      }
}
