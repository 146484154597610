import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ATInternetService } from '@ff/at-internet';
import { UserService } from '@ff/user';
import { Campaign } from 'app/models/campaign';
import { Optin } from 'app/models/optin';
import { CguService } from 'app/services/cgu.service';
import { DataService } from 'app/services/data.service';
import { NetworkService } from 'app/services/network.service';
import { OptinsService } from 'app/services/optins.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public atInternet: ATInternetService,
    private _snackBar: MatSnackBar,
    private _networkService: NetworkService,
    private _userService: UserService,
    private _cguService: CguService,
    private _optins: OptinsService,
    private _data: DataService
  ) {}

  ngOnInit(): void {
    this._networkService.initEvents();
    if (this._userService.getUser().value) {
      this._cguService.checkCGU();
      localStorage.setItem('user', JSON.stringify(this._userService.getUser().value));
      this.getOptins();
      this.getCampaign();
    }
  }

  getOptins() {
    this._optins.getOptins().subscribe((optins: Optin[]) => {
      let tmp = [];
      optins.forEach(o => tmp.push(o.toJson()));
      localStorage.setItem('optins', JSON.stringify(tmp));
    });
  }

  getCampaign() {
    this._data.getCampaign().subscribe((campaign: Campaign) => {
      localStorage.setItem('campaign', JSON.stringify(campaign.toJson()));
    });
  }


}
