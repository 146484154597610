<div class="d-flex flex-row">
    <div class="custom-spinner-container">
        <svg class="custom-spinner" preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100" style="width: 100px; height: 100px;">
            <circle cx="50%" cy="50%" r="45" stroke="url(#grad1)"></circle>
            <defs>
                <linearGradient id="grad1" x1="0" y1="0" x2="320" y2="320" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#029BEB"/>
                    <stop offset="1" stop-color="#00AA61"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div class="d-flex flex-column">
        <div class="snack-title" *ngIf="data && data.title">{{data?.title | translate}}</div>
        <div class="snack-text" *ngIf="data && data.text">{{data?.text | translate}}</div>
    </div>
</div>