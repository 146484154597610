import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {endpoints} from 'environments/endpoints';
import {environment} from 'environments/environment';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, delay, switchMap} from 'rxjs/operators';
import {NetworkService} from './network.service';
import {SalesforceService} from './salesforce.service';
import { isNil } from "lodash";
import * as _moment from 'moment';

const moment = _moment;

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(
        private http: HttpClient,
        private salesforceService: SalesforceService
    ) { 
        
    }
    
    create(data: any): Observable<any> {
        data.PersonBirthDate = !isNil(data.PersonBirthDate) ? moment(data.PersonBirthDate).format('YYYY-MM-DD') : null;
        if (!!window.navigator.onLine) {
            return this.salesforceService.createAccount(data);
        } else {
            return this.createAccountInLocalStorage(data);
        }
	}
    
    sync(data: any): Observable<any> {
        if (!!window.navigator.onLine) {
            let dataToSync = [];
            let delayValue = 0;
            
            data.forEach((customer) => {
                dataToSync.push(this.salesforceService.createAccount(customer.data, delayValue).pipe(catchError(response => { 
                    return of('fail'); 
                })));
                delayValue += 1000;
            });
            
            return forkJoin(dataToSync);
        } else {
            return of('NO_INTERNET');
        }
    }
    
    getFromLocalStorage() {
        return JSON.parse(localStorage.getItem('sms_customers'));
    }
    
    createAccountInLocalStorage(data: any): Observable<any> {
        let customers = [];
        let customerData;
        let storageCustomers = localStorage.getItem('sms_customers');
        
        if (storageCustomers != null) {
            customers = JSON.parse(storageCustomers);
        }
        
        customerData = {
            'data': data,
            'created_at': new Date(),
            'status': 'Not synced' 
        };
        
        customers.push(customerData);
        
        localStorage.setItem('sms_customers', JSON.stringify(customers));
        
        return of('storage');
    }
    
    updateAccountInLocalStorage(customers: any): void {
        localStorage.removeItem('sms_customers');
        localStorage.setItem('sms_customers', JSON.stringify(customers));
    }
    
    protected handleError(error: any): Promise<any> {
		if (error.status === 401) {
			window.location.reload();
		}

		return Promise.reject(error?.error?.message || error?.message || error);
	}
}
