<header>
    <div class="d-flex align-items-center w-100">
  
      <img src="assets/logo.svg" [routerLink]="'/home'" title="Total" alt="Total" class="logo pointer" />
      <div class="d-flex flex-column menu-container">
        <div class="d-flex flex-row w-100 align-items-center title-row">
          <div class="app-title">{{ 'app_title' | translate}}</div>
          <div class="d-flex flex-column justify-content-center" style="margin-left: auto;">
            <div class="d-flex flex-row align-items-center">
              <ng-container *ngIf="userService.getUser() | async">
                <div class="icon-override logout-icon size-20 pointer" (click)="logout()" style="margin-left: auto; margin-right: 12px"></div>
              </ng-container>
            </div>
          </div>
          
          <div class="network-status {{_networkService?.isOnline ? 'online' : 'offline'}}">
            <div class="icon-override {{_networkService?.isOnline ? 'online' : 'offline'}}-icon size-20" matPrefix style="margin-right: 12px"></div>
            {{_networkService?.isOnline ? 'online' : 'offline'}}
          </div>
        </div>
        <hr class="w-100" *ngIf="userService.getUser() | async">
        <div class="d-flex flex-row" *ngIf="(userService.getUser() | async) && routes.length > 0">
          <app-navigation [nav]="routes"></app-navigation>
        </div>
      </div>
      
      
    </div>
  </header>