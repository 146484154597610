import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { equals, password } from '@ff/core';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnDestroy {


    private _userInfoForm: FormGroup;
    private _userPasswordForm: FormGroup;
    private _user: any;
    private _subscriptions: Subscription[] = [];

    constructor(
        fb: FormBuilder,
        private _userService: UserService,
        private _loaderService: LoaderService,
        private _snackBar: MatSnackBar
    ) {
        this._user = _userService.getUser().getValue();
        this._userInfoForm = fb.group(
            {
                id: [this._user.id, [Validators.required]],
                first_name: [this._user.first_name, [Validators.required, Validators.maxLength(255)]],
                last_name: [this._user.last_name, [Validators.required, Validators.maxLength(255)]],
                email: [{
                    value: this._user.email,
                    disabled: true
                }, [Validators.required, Validators.maxLength(255), Validators.email]]
            }
        );

        this._userPasswordForm = fb.group(
            {
                oldPassword: ['', [Validators.required]],
                newPassword: ['', [
                    Validators.required,
                    Validators.minLength(8),
                    password()
                ]],
                newPassword_confirmation: ['', [Validators.required, equals('newPassword')]]
            }
        );

        this._subscriptions.push(
            this._userPasswordForm.controls.newPassword.valueChanges.subscribe(
                () => {
                    this._userPasswordForm.controls.newPassword_confirmation.setValue('');
                })
        );
    }

    public getUserInfoForm(): FormGroup {
        return this._userInfoForm;
    }

    public getUserPasswordForm(): FormGroup {
        return this._userPasswordForm;
    }

    public saveUserInfo() {
        this._loaderService.show();
        this._userService.save(this._userInfoForm).then(
            (response: any) => {
                this._userInfoForm.patchValue({...response.data});
                this._userService.getUser().next(response.data);
                this._snackBar.open('Your account has been updated.', 'Dismiss', {duration: 3000});
                this._loaderService.hide();
            }
        )
            .catch(
                (response: any) => {
                    this._snackBar.open('Oops, an error happened! Please try again.', 'Dismiss', {duration: 3000});
                    this._loaderService.hide();
                }
            );
    }

    public saveUserPassword() {

        this._loaderService.show();
        this._userService.changePassword(this._userPasswordForm).then(
            (response: any) => {
                this._snackBar.open(response.message, 'Dismiss', {duration: 3000});
                this._userPasswordForm.reset();
                this._loaderService.hide();
            }
        )
            .catch(
                (response: any) => {
                    this._snackBar.open(response.error.errors.password, 'Dismiss', {duration: 3000});
                    this._loaderService.hide();
                }
            );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(
            (subscription: Subscription) => subscription.unsubscribe()
        );
    }

}
