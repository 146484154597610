import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Optin } from 'app/models/optin';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OptinsService {

  resource: string;

  constructor(
    private http: HttpClient
  ) {
    this.resource = environment.api.host + '/optins';
  }

  getOptins() {
    if (!!window.navigator.onLine) {
      return this.http.get<{ data: Array<Optin> }>(this.resource).pipe(map((res: any) => res.data.map((o: Optin) => new Optin(o))));
    } else {
      return new Observable((subscribe) => {
        subscribe.next(JSON.parse(localStorage.getItem('optins')).map((o: Optin) => new Optin(o)));
        subscribe.complete();
      });
    }
  }

  // delete(id: number): Observable<any> {
  //   return this.http.delete(this.resource + '/' + id);
  // }

  // save(formValue) {
  //   console.log(formValue);
  //   if (formValue && formValue.id) {
  //     return this.edit(formValue);
  //   }
  //   return this.http.post<{ data: User }>(this.resource, formValue);
  // }

  edit(formValue) {
    return this.http.put<{ data: any }>(this.resource + '/' + formValue.id, formValue);
  }
}
