<div class="container-fluid">
  <div class="row no-gutters">
      <h1 class="col-12 pb-3">My account</h1>
      <div class="d-flex align-items-center w-100 mb-3">
        <small>Fields with a star (*) are required</small>
        <a class="ml-auto d-lg-flex align-items-center d-none" routerLink="/auth/logout">
          <mat-icon>arrow_right</mat-icon> Logout
        </a>
      </div>
      <div class="d-flex user-info justify-content-center flex-column col-12 mb-4" [formGroup]="getUserInfoForm()">
        <div class="row">
          <h2 class="col-12">Identity</h2>
          <mat-form-field class="col-12 col-lg-4">
            <input type="text" matInput placeholder="Firstname*" formControlName="first_name" />
          </mat-form-field>
          <mat-form-field class="col-12 col-lg-4">
            <input type="text" matInput placeholder="Lastname*" formControlName="last_name" />
          </mat-form-field>
          <mat-form-field class="col-12 col-lg-4">
            <input type="email" matInput placeholder="Email*" formControlName="email" />
          </mat-form-field>
          <div class="col-12 d-flex justify-content-end pt-3">
            <button mat-raised-button color="primary" (click)="saveUserInfo()" [disabled]="getUserInfoForm().invalid">
              <mat-icon class="d-none d-lg-inline">arrow_right</mat-icon>
              Update my account
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex user-password justify-content-center flex-column col-12 mb-4" [formGroup]="getUserPasswordForm()">
        <div class="row">
          <h2 class="col-12 d-flex align-items-center">Password <mat-icon class="ml-3 hint" matTooltipClass="hint-text" matTooltip="Your password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character.">info</mat-icon></h2>
          <mat-form-field class="col-12 col-lg-4">
            <input type="password" matInput placeholder="Current password*" formControlName="oldPassword" />
          </mat-form-field>
          <mat-form-field class="col-12 col-lg-4">
            <input type="password" matInput placeholder="New password*" formControlName="newPassword" />
          </mat-form-field>
          <mat-form-field class="col-12 col-lg-4">
            <input type="password" matInput placeholder="Confirm your password*" formControlName="newPassword_confirmation" />
          </mat-form-field>
          <div class="col-12 d-flex justify-content-end pt-3">
            <button mat-raised-button color="primary" (click)="saveUserPassword()" [disabled]="getUserPasswordForm().invalid">
                <mat-icon class="d-none d-lg-inline">arrow_right</mat-icon>
                Update my password
            </button>
          </div>
        </div>
      </div>
  </div>
</div>
