<h2>{{ 'cgu.title' | translate }}</h2>
<div mat-dialog-content>
    <div class="cgu-container" [innerText]="text" ></div>
</div>
<div class="cgu-confirm">
    <mat-checkbox [checked]="cguChecked" (change)="checkCGU($event.checked)" style="margin: 8px 0">{{'cgu.confirm' | translate}}</mat-checkbox>
</div>
<a href="{{link}}" target="_blank">{{link}}</a>
<div mat-dialog-actions style="margin-left: auto;width: fit-content; padding: 20px 0;margin-top: 20px" class="d-flex flex-end">
    <button
            style="width:120px"
            class="buttonCancel"
            (click)="dialogRef.close()"
    >
        <span class="grey">{{ 'cancel' | translate }}</span>
    </button>
    <button 
        class="buttonValid"
        [disabled]="!cguChecked"
        (click)="_cguService.confirmCGU()"
        mat-raised-button
    >
        <span>{{ 'confirm' | translate}}</span>
    </button>
</div>
