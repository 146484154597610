export class User {
    id: number;
    first_name: string;
    last_name: string;
    igg?: string;
    roles: string[];
    email: string;
    country?: string;
    station_id?: string;
    attendant_id?: string;
    constructor (fill: User) {
        this.id = fill.id;
        this.first_name = fill.first_name;
        this.last_name = fill.last_name;
        this.igg = fill.igg;
        this.roles = fill.roles;
        this.email = fill.email;
        this.country = fill.country;
        this.station_id = fill.station_id;
        this.attendant_id = fill.attendant_id;
    }
}

export enum UserRoles {
    SPADMIN = 'spadmin',
    ADMIN = 'admin',
    STATION_STAFF = 'station_staff'
}