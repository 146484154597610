
export interface ICampaign {
    id?: number;
    key: string;
    country: string;
}

export class Campaign implements ICampaign{
    id?: number;
    key: string;
    country: string;

    constructor (fill: Campaign) {
        this.id = fill.id;
        this.key = fill.key;
        this.country = fill.country;
    }

    toJson() {
        return {
          id: this.id,
          key: this.key,
          country: this.country
        };
      }
}
