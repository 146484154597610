import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@ff/core';

import { LoaderModule, LoaderService } from '@ff/loader';
import { ThemeModule } from '@ff/theme';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './material.module';
import { SuccessSnackbarComponent } from './components/success-snackbar/success-snackbar.component';
import { WarningSnackbarComponent } from './components/warning-snackbar/warning-snackbar.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { ProgressSnackbarComponent } from './components/progress-snackbar/progress-snackbar.component';
import { CguComponent } from './components/cgu/cgu.component';
import { SyncDoneSnackbarComponent } from './components/sync-done-snackbar/sync-done-snackbar.component';

@NgModule({
    declarations: [SuccessSnackbarComponent, WarningSnackbarComponent, ErrorSnackbarComponent, ProgressSnackbarComponent, CguComponent, SyncDoneSnackbarComponent],
    providers: [],
    imports: [
        // Angular
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
        // Angular Material
        MaterialModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,
        TranslateModule,
        // Angular Material
        MaterialModule,
    ]
})

export class SharedModule {
    public constructor(
        private _translate: TranslateService
    ) {
        this._translate.setDefaultLang('en');
        this._translate.use('en');
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function loaderShow(_loaderService: LoaderService) {
    if (!_loaderService.isVisible()) {
      _loaderService.show();
    }
}

export function loaderHide(_loaderService: LoaderService) {
    if (_loaderService.isVisible()) {
        _loaderService.hide();
    }
}
