import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@ff/user';
import { CguComponent } from 'app/modules/shared/components/cgu/cgu.component';

@Injectable({
  providedIn: 'root'
})
export class CguService {

  dialogRef: any;
  text = 'You must read and accept the general conditions of use to access the application';
  links = {
    EG: 'https://totalenergies.com.lb/en/legal',
    LB: 'https://totalenergies.com.lb/en/legal',
  };
  constructor(
    private dialog: MatDialog,
    private _userService: UserService
  ) {
    
  }

  getLink(countryCode: string): string {
    return this.links[countryCode];
  }

  openCGU(): void {
    this.dialogRef = this.dialog.open(CguComponent, {
      data: this.text,
      width: '620px',
    });
  }

  confirmCGU(): void {
    const cguCookie = {
      email: this._userService._user?.value?.email,
      text: this.text
    };
    localStorage.setItem('cgu', JSON.stringify(cguCookie));
    this.dialogRef.close();
  }

  hasReadCGU(): boolean {
    if (localStorage.getItem('cgu')) {
      const cguCookie = JSON.parse(localStorage.getItem('cgu'));
      return cguCookie.text == this.text && this._userService._user?.value?.email == cguCookie.email;
    }
    return false;
  }

  checkCGU(): void {
    if (!this.hasReadCGU()) {
      this.openCGU();
    }
  }
}
