import { Component, Host, Input, OnDestroy, Optional } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Route, Router } from '@angular/router';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {

    private readonly _routes: Route[] = [];
    private _alive: Subject<any>;
    
    @Input() nav = [];

    constructor(@Host() @Optional() private _sidenav: MatSidenav,
                private _router: Router) {
        this._alive = new Subject();
        this._routes = _router.config;
    }

    public getRoutes(): Route[] {
        return this.nav.length > 0 ? this.nav : this._routes;
    }

    public close(): void {
        if (this._sidenav) {
            this._sidenav.close();
        }
    }

    ngOnDestroy() {
        this._alive.next();
        this._alive.complete();
    }

}
