import { TranslateService } from "@ngx-translate/core";
import { isNil } from "lodash";
import { ServiceLocator } from "../services/_locator.service";

export interface ITranslatable {
    fr?: string;
    en?: string;
}

export class Translatable implements ITranslatable {
    fr?: string;
    en?: string;

    private _translate: TranslateService = ServiceLocator.injector.get(TranslateService);
    private currentLang: string = this._translate.currentLang;

    constructor(fill: {}) {
        if (fill) {
            Object.assign(this, fill);
        }
        this._translate.onDefaultLangChange.subscribe({
            next: (res: any) => {
                this.currentLang = res.lang;
            }
        });
    }

    translate() {
        return !isNil((this as any)[this.currentLang]) ? (this as any)[this.currentLang] : '';
    }

    toJson() {
        let res = {};
        this._translate.getLangs().forEach(lang => {
            res[lang] = this[lang];
        });
        return res;
    }
}