import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-progress-snackbar',
  templateUrl: './progress-snackbar.component.html',
  styleUrls: ['./progress-snackbar.component.scss']
})
export class ProgressSnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { 
    
  }

  ngOnInit(): void {
  }

}
