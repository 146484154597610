import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {endpoints} from 'environments/endpoints';
import {environment} from 'environments/environment';
import {forkJoin, Observable, of} from 'rxjs';
import {delay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
    constructor(
        private http: HttpClient
    ) { 
    }   
    
    createAccount(data, delayValue: number = 0): Observable<any> {;
       return this.http.post(environment.api.host + endpoints.salesforceAccount, data).pipe(
        delay(delayValue),
        switchMap((response: any) => {
                return this.getOptinObservables(data, response.data.id);
            })
        );
    }
    
    getOptinObservables(data, accountId): Observable<any[]> {
        let optins = data.optin;
        let observables = [];
        if (optins?.length > 0) {
            optins.forEach((optin) => {
                if (optin.canals?.length > 0) {
                    optin.canals.forEach((canal) => {
                        let optinData = {
                            RecordTypeId: '0121v000000lXdSAAU', //PROD
                            OPT_Account_Name__c: accountId,
                            OPT_Opt_In_Name__c: optin.optin,
                            OPT_Is_Opt_in__c: (canal.checked == true ? true : false) + '',
                            OPT_Distribution_Channel__c: data.ACC_Affiliate__c + '_'+ (canal.type === 'sms' ? 'SMS' : (canal.type == 'email' ? 'Email' : canal.type)),
                            OPT_Opt_In_Date__c: (canal.checked ? new Date() : ''),
                            OPT_Is_Opt_Out__c: (canal.checked == true ? false: true) + '',
                            OPT_Opt_Out_Date__c: (!canal.checked ? new Date() : ''),
                            OPT_Opt_in_Language__c: data.ACC_Preferred_Language__c,
                            OPT_Datasource__c : data.ACC_Datasource__c,
                            OPT_Preferences__c: "",
                            OPT_Opt_In_Origin__c : (canal.checked ? data.ACC_Acquisition_Source__c : ''),
                            OPT_Opt_Out_Origin__c : (!canal.checked ? data.ACC_Acquisition_Source__c : ''),
                            Opt_Country__c: data.ACC_Affiliate__c,
                        };
                        observables.push(this.setOptin(optinData, null, accountId));
                    });
                }
            });
        }
        return forkJoin(observables.length > 0 ? observables : of([]));
    }

    setOptin(optinData, existingOptin, accountId) {        
        if (existingOptin != undefined && existingOptin != null) {
            optinData['opt_in_id'] = existingOptin.Id;
            return this.http.patch(environment.api.host + endpoints.salesforceOptin, optinData);
        } else {
            return this.http.post(environment.api.host + endpoints.salesforceOptin, optinData);
        }
    }
}
