import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CguComponent } from 'app/modules/shared/components/cgu/cgu.component';
import { CguService } from 'app/services/cgu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    public _cguService: CguService
  ) {
  }

  ngOnInit() {
  }

  


}
