// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
    api: {
      host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net'
    },
    salesforce: {
         oauth2bis: {
            host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net/salesforce/lightningforce/oauth2'
        },
        oauth2: {
            host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net/salesforce/oauth2'
        },
        services: {
            host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net/salesforce/services'
        },
        query: {
            host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net/salesforce/query'
        },
        apex: {
            host: 'https://api-sms-local-provider.mpsprod.alzp.tgscloud.net/salesforce/apex'

        }
    }
};
