import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Campaign } from 'app/models/campaign';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  resource: string;

  constructor(
    private http: HttpClient
  ) {
    this.resource = environment.api.host + '/data';
  }

  getCampaign() {
    if (!!window.navigator.onLine) {
      return this.http.get<{ data: Campaign }>(this.resource + '/campaign').pipe(map((res: any) => new Campaign(res.data)));
    } else {
      return new Observable((subscribe) => {
        subscribe.next(new Campaign(JSON.parse(localStorage.getItem('campaign'))));
        subscribe.complete();
      });
    }
  }

  // delete(id: number): Observable<any> {
  //   return this.http.delete(this.resource + '/' + id);
  // }

  // save(formValue) {
  //   console.log(formValue);
  //   if (formValue && formValue.id) {
  //     return this.edit(formValue);
  //   }
  //   return this.http.post<{ data: User }>(this.resource, formValue);
  // }

  editCampaign(campaign: Campaign) {
    return this.http.post<{ data: any }>(this.resource + '/campaign/' + campaign.id, campaign);
  }
}
